<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-alert
      type="success"
      dense
      transition="slide-y-transition"
      :value="isUpdateSuccess"
      >更新成功！</v-alert
    >
    <v-row>
      <v-col cols="11">
        <v-form ref="form">
          <v-card flat max-width="600px">
            <v-card-title>用户信息</v-card-title>
            <v-card-text class="ml-4">
              <v-text-field
                filled
                dense
                label="用户机构显示名称"
                v-model="userName"
                :rules="fieldRules.userName"
              ></v-text-field>
              <v-textarea
                filled
                dense
                label="用户机构描述"
                v-model="userDesc"
                rows="1"
                auto-grow
                :rules="fieldRules.userDesc"
              ></v-textarea>
            </v-card-text>
            <v-card-title>测量端设置</v-card-title>
            <v-card-text class="ml-4">
              <v-select
                filled
                dense
                label="默认的被试者信息输入界面"
                :items="primaryPersonInputTypes"
                item-value="value"
                item-text="text"
                v-model="selectedPrimaryPersonInput"
              ></v-select>
            </v-card-text>
            <v-card-actions class="ml-4 my-6">
              <v-btn
                color="primary"
                depressed
                @click="saveUserCustInfo"
                :disabled="isBtnLoading"
                :loading="isBtnLoading"
                >确认修改</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import {
  personInputTypes,
  fetchUserCustInfo,
  fetchPrimaryPersonInput,
  saveUserCustInfo
} from "@/api/user";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  data() {
    return {
      selectedPrimaryPersonInput: "",
      userName: "",
      userDesc: "",
      fieldRules: {
        userName: [
          val => (val || "").length > 0 || "机构显示名称不能为空",
          val => (val || "").length < 50 || "机构显示名称不能超过50个字符"
        ],
        userDesc: [
          val => (val || "").length < 1000 || "机构描述不能超过1000个字符"
        ]
      },
      isUpdateSuccess: false,
      errorMsg: "",
      isLoading: false,
      isBtnLoading: false
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid"
    }),
    primaryPersonInputTypes() {
      return _.map(personInputTypes, val => val);
    }
  },

  methods: {
    ...mapActions({
      setUserDispName: "user/setUserDispName"
    }),
    showSuccessMsg() {
      this.isUpdateSuccess = true;
      setTimeout(() => (this.isUpdateSuccess = false), 2000);
    },
    async reloadUserCustInfo() {
      try {
        this.isLoading = true;
        let userCustInfo = await fetchUserCustInfo(this.userGuid);
        this.userName = userCustInfo.displayName;
        this.userDesc = userCustInfo.description;
        this.selectedPrimaryPersonInput = await fetchPrimaryPersonInput(
          this.userGuid
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async saveUserCustInfo() {
      let isValid = this.$refs.form.validate();
      if (isValid) {
        try {
          this.isBtnLoading = true;
          await saveUserCustInfo(this.userGuid, {
            userName: this.userName,
            userDesc: this.userDesc,
            primaryPersonInput: this.selectedPrimaryPersonInput
          });
          this.setUserDispName(this.userName);
          this.showSuccessMsg();
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isBtnLoading = false;
      }
    }
  },

  async created() {
    await this.reloadUserCustInfo();
  }
};
</script>
