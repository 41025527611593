<template>
  <v-container>
    <v-row>
      <v-col cols="11">
        <v-card flat>
          <v-card-title>{{ previewTitle }}</v-card-title>
          <div class="mx-6 mb-6 page-container">
            <div class="page-a4" v-html="pageHtml"></div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    previewTitle: {
      type: String,
      required: true
    },
    pageHtml: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  overflow-x: auto;
  .page-a4 {
    width: 210mm;
    height: 297mm;
    border: 1px solid #666;
  }
}
</style>
