<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs show-arrows>
            <v-tab>基本设置</v-tab>
            <v-tab>报告设置</v-tab>
            <v-tab>显示设置</v-tab>
            <v-tab>登录设置</v-tab>
            <v-tab v-if="!!coverPageHtml">报告封面预览</v-tab>
            <v-tab-item>
              <AdminSettingsInfo />
            </v-tab-item>
            <v-tab-item>
              <AdminSettingsReport />
            </v-tab-item>
            <v-tab-item>
              <FieldConfigEditor
                :user-entity="userEntity"
                :user-guid="userGuid"
              />
            </v-tab-item>
            <v-tab-item>
              <CredentialsEditor
                :guid="userGuid"
                :entity="customerEntityType"
              />
            </v-tab-item>
            <v-tab-item v-if="!!coverPageHtml">
              <ReportPagePreview
                :page-html="coverPageHtml"
                preview-title="报告封面预览"
              />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppMessageBox from "@/components/AppMessageBox";
import AdminSettingsInfo from "@/components/admin/AdminSettingsInfo";
import AdminSettingsReport from "@/components/admin/AdminSettingsReport";
import CredentialsEditor from "@/components/CredentialsEditor";
import FieldConfigEditor from "@/components/FieldConfigEditor";
import ReportPagePreview from "@/components/ReportPagePreview";
import { initNewReportInfo, fetchCoverPageBody } from "@/api/reportInfo";
import { userEntityTypes } from "@/api/user";
import { mapGetters } from "vuex";

export default {
  components: {
    AppMessageBox,
    AdminSettingsInfo,
    AdminSettingsReport,
    CredentialsEditor,
    FieldConfigEditor,
    ReportPagePreview
  },

  data() {
    return {
      coverPageHtml: "",
      errorMsg: ""
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      userEntity: "user/userEntity"
    }),
    customerEntityType() {
      return userEntityTypes.customer;
    }
  },

  methods: {
    async initReportInfo() {
      try {
        await initNewReportInfo(this.userGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async fetchCoverPageHtml() {
      try {
        this.coverPageHtml = await fetchCoverPageBody(
          this.userEntity,
          this.userGuid
        );
      } catch (err) {
        this.coverPageHtml = "";
      }
    }
  },

  async created() {
    await this.initReportInfo();
    await this.fetchCoverPageHtml();
  }
};
</script>
