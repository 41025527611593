<template>
  <v-container>
    <v-alert
      type="success"
      dense
      transition="slide-y-transition"
      :value="isUpdateSuccess"
      >更新成功！</v-alert
    >
    <v-row>
      <v-col cols="11">
        <v-form ref="form">
          <v-card flat max-width="600px">
            <v-card-title>主试机构设置</v-card-title>
            <v-card-text>
              <v-text-field
                filled
                dense
                label="主试机构"
                hint="案例报告底部左侧的主试机构名称"
                v-model="reportOrgName"
                :rules="fieldRules.name"
              ></v-text-field>
            </v-card-text>
            <v-card-title>报告署名候选</v-card-title>
            <v-card-text>
              <div>
                <v-chip
                  v-for="(candiName, idx) in reporterCandidates"
                  :key="idx"
                  class="mr-2 mb-4"
                  close
                  @click:close="reporterCandidates.splice(idx, 1)"
                >
                  {{ candiName }}
                </v-chip>
              </div>
              <v-text-field
                dense
                label="报告署名候选"
                hint="报告底部的署名选择"
                v-model="newCandidateName"
              >
                <template v-slot:append-outer>
                  <v-btn
                    text
                    small
                    color="primary"
                    @click="reporterCandidates.push(newCandidateName)"
                  >
                    <v-icon>mdi-plus</v-icon>
                    添加
                  </v-btn>
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-actions class="ml-4 mb-6">
              <v-btn
                color="primary"
                depressed
                @click="saveReportInfo"
                :disabled="isBtnLoading"
                :loading="isBtnLoading"
                >确认修改</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppMessageBox from "@/components/AppMessageBox";
import { mapGetters } from "vuex";
import { fetchReportSignInfo, saveReportSignInfo } from "@/api/reportInfo";

export default {
  components: {
    AppMessageBox
  },

  data() {
    return {
      isLoading: false,
      reportInfoGuid: "",
      reportOrgName: "",
      reporterCandidates: [],
      newCandidateName: "",
      fieldRules: {
        name: [
          val => (val || "").length > 0 || "机构显示名称不能为空",
          val => (val || "").length < 25 || "机构显示名称不能超过30个字符"
        ]
      },
      isUpdateSuccess: false,
      errorMsg: "",
      isBtnLoading: false
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      userEntity: "user/userEntity"
    })
  },

  methods: {
    showSuccessMsg() {
      this.isUpdateSuccess = true;
      setTimeout(() => (this.isUpdateSuccess = false), 2000);
    },
    async loadReportSignInfo() {
      try {
        this.isLoading = true;
        var reportSign = await fetchReportSignInfo(
          this.userEntity,
          this.userGuid
        );
        this.reportInfoGuid = reportSign.reportInfoGuid;
        this.reportOrgName = reportSign.reportOrgName;
        this.reporterCandidates = reportSign.reporterCandidates;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async saveReportInfo() {
      var isValid = this.$refs.form.validate();
      if (isValid) {
        try {
          this.isBtnLoading = true;
          await saveReportSignInfo(this.reportInfoGuid, {
            reportOrgName: this.reportOrgName,
            reporterCandidates: this.reporterCandidates
          });
          this.showSuccessMsg();
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isBtnLoading = false;
      }
    }
  },

  async created() {
    await this.loadReportSignInfo();
  }
};
</script>
